import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Loadable from '@loadable/component';
import useModelList from 'hooks/useModelList';
import Master from 'layouts/Master';
import LoadingView from 'components/LoadingView';
import Model from 'models/Model';
import Tag from 'models/Tag';
import Config from 'config/Config';
import RouteUtils from 'utils/RouteUtils';

// Fix the image sorting bug of Gatsby
// https://github.com/gatsbyjs/gatsby/issues/20819
const LoadableModelList = Loadable(() => import('components/ModelList'));

type Data = {
  pageData: {
    models: Model[];
    tags: Tag[];
  };
};

type Props = {
  data: Data;
};

export default function IndexPage(props: Props) {
  const [query, setQuery] = useState('');
  const { isLoading, models } = useModelList(props.data.pageData.models);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const onSearchKeyDown = (e: React.KeyboardEvent) => {
    if (e.which === 13) {
      onSearchClick();
    }
  };

  const onSearchClick = () => {
    window.location.href = RouteUtils.toSearch(query);
  };

  return (
    <Master title={`${Config.SiteTitle} | ${Config.SiteDescription}`}>
      <div className="container">

        <h1 className="page__title">
          Big Tits Webcam Girls
        </h1>
        <h2 className="page__subtitle">
          Free Live Camgirls with Big Boobs.<br/>
          No ads, no redirects, no popups.
        </h2>

        <div className="index-page__search-container">
          <input
            type="text"
            name="search"
            value={query}
            placeholder={`Search by username or category`}
            onChange={onSearchChange}
            onKeyDown={onSearchKeyDown}
            className="index-page__search-input"
          />
          <button
            onClick={onSearchClick}
            className="button button--primary index-page__search-button"
          >
            <FontAwesomeIcon icon={['fas', 'search']} />
          </button>
        </div>

        {isLoading && (
          <LoadingView
            position="relative"
            message="Checking online camgirls"
            className="index-page__loading-view"
          />
        )}

        <LoadableModelList models={models} sortBy="online" className="index-page__camgirls-list"/>
      </div>
    </Master>
  );
}

export const pageQuery = graphql`
  {
    pageData: allCamGirl {
      models: nodes {
        username
        id
        followersCount
        tags
      },
      tags: group(field: tags) {
          name: fieldValue
        }
    }
  }
`;
